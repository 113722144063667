import React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import classNames from "classnames";

import s from "./Footer.module.css";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      footerImage: file(relativePath: { eq: "light-safe-candle-white.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
        extension
        publicURL
      }
      facebookLogo: file(relativePath: { eq: "facebook.svg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
        extension
        publicURL
      }
      linkedInLogo: file(relativePath: { eq: "linkedin.svg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
        extension
        publicURL
      }
    }
  `);
  return (
    <footer className={s.footer}>
      <div className="center">
        <img
          className={s.logo}
          height={200}
          width={300}
          src={data.footerImage.publicURL}
        />
      </div>
      <div className={s.links}>
        <Link to="/privacy-policy" className={s.navLink}>
          Privacy Policy
        </Link>
        <Link to="/terms-of-service" className={s.navLink}>
          Terms Of Service
        </Link>
        <div className={s.socailIcon}>
          <a
            href="https://business.facebook.com/Lightsafecandle/"
            target="_blank"
          >
            <img className={s.facebookLogo} src={data.facebookLogo.publicURL} />
          </a>
          <a
            href="https://www.linkedin.com/company/light-safe-candle/"
            target="_blank"
          >
            <img className={s.facebookLogo} src={data.linkedInLogo.publicURL} />
          </a>
        </div>
      </div>
      <div className={classNames(s.links, s.top)}>
        <Link to="/#about" className={s.navLink}>
          About Us
        </Link>
        <Link to="/churches" className={s.navLink}>
          List Of Churches
        </Link>
        <Link to="/terms-of-service" className={s.navLink}>
          Learn More
        </Link>
        <Link to="/#contact" className={s.navLink}>
          Contact Us
        </Link>
        <Link to="/product" className={s.navLink}>
          Product
        </Link>
        <Link to="/blog" className={s.navLink}>
          Blog
        </Link>
      </div>
      <div className={classNames(s.copyright, "center")}>
        All Rights Reserved LSC LLC &nbsp;&nbsp; | &nbsp;&nbsp; USA Patent
        Pending
      </div>
    </footer>
  );
};

export default Footer;

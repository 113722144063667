import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { ToastContainer } from "react-toastify";
import Header from "./Header";
import Footer from "./Footer";

import "normalize.css";
import "react-toastify/dist/ReactToastify.css";
// import "react-modal-video/css/modal-video.min.css";
import "./layout.css";
import s from "./layout.module.css";

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]');
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
      <ToastContainer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

import React, { useState, useEffect } from "react";
// import ModalVideo from "react-modal-video";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import { useQueryParam } from "gatsby-query-params";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";

import s from "./Header.module.css";
import { useSizes } from "../../utils/sizes";

const Header = () => {
  const startVideo = useQueryParam("startVideo", false);
  const [isOpen, setOpen] = useState(!!startVideo);
  const [width, height] = useSizes();
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "light-safe-candle.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
        extension
        publicURL
      }
    }
  `);

  useEffect(() => {
    setOpen(startVideo);
  }, [startVideo]);

  const onClose = () => {
    setOpen(false);
    navigate("/");
  };

  const locati = useLocation();
  const path = locati.pathname.slice(0, 10);

  return (
    <>
      <header className={s.header}>
        <div className={s.nav}>
          <Link to="/#about" className={s.navLink}>
            <span>About</span>
          </Link>
          <Link to="/churches" className={s.navLink}>
            List Of Churches
          </Link>
          {!("/churches/" == path && width < 700) && (
            <Link title="contact" to="/#contact" className={s.navLink}>
              Contact for Business Inq.
            </Link>
          )}
        </div>
        <div className="logo-wrapper">
          <img
            className={s.logo}
            height={100}
            width={200}
            src={data.placeholderImage.publicURL}
            onClick={() => navigate("/")}
          />
        </div>
        {!("/churches/" == path && width < 700) && (
          <div className={s.nav}>
            <Link to="/?startVideo=true" className={s.navLink}>
              Learn More
            </Link>
            <Link to="/privacy-policy" className={s.navLink}>
              Privacy Policy
            </Link>
            <Link to="/terms-of-service" className={s.navLink}>
              Terms Of Service
            </Link>
          </div>
        )}
      </header>
      {/* <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="co-zXu2w24A"
        onClose={onClose}
      /> */}
    </>
  );
};

export default Header;

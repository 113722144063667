import { useEffect, useState } from "react";

export const useSizes = () => {
  const [width, setWidth] = useState(undefined);
  const [height, setHeight] = useState(undefined);
  useEffect(() => {
    const handler = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    handler();
    window.addEventListener("resize", handler);
    return () => window.removeEventListener("resize", handler);
  }, []);
  return [width, height];
};
